.center-text {
  text-align: center;
}

#landing-bg {
  height: 75vh;
  background-image: url('3watches.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

#landing-cta {
  height: 0%;  
}

.image {
   object-fit: fill;
   width: 100%;
}

#notification-complication {
  height: 50vh;
  background-image: url('notificationComplication.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#rifle {
  height: fit-content;
  background-image: url('rifle.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top right;
}

#pistol {
  margin-top: 50pt;
  height: 115pt;
  background-image: url('pistol.jpg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left top;
}

#shotgun {  
  height: 100pt;
  background-image: url('shotgun.jpg');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: top center;
}

#line {
 height: 100vh;
}

#privacy {
  height:100vh;
  background-image: url('Lock.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

html, body {
  background-color: black;
}

h1, h2, h3, h4, h5, h6, p {
  color: white;
}

p {
  font-size: 15pt;
}

.sticky {
    position: relative;
    z-index:1000000!important;
}

.secondary-text {
  color: rgb(213, 213, 213);
}

.form-label {
  color: white;
}

.cta {
  text-align: center;
  text-shadow: 0px 0px 15px rgb(0, 0, 0);
}

.shadow {
  text-shadow: 0px 0px 15px rgb(0, 0, 0);
}

.deep-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.18);
}

#compete {
  margin-bottom: 50pt;
}

#BNE {
  margin-bottom: 50pt;
}

.graph-info {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer {
  text-align: center;
  height: 50pt;
  margin-top: 100pt;
}

.footer-row {
  margin-bottom: 10pt;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
#landing-bg {
  height: 150vh;
  background-image: url('3watches.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

#rifle {
  height: 100%;
  background-image: url('rifle.jpg');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: top right;
}

#pistol {
  height: 100pt;
  background-image: url('pistol.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left center;
}

#shotgun {
  height:100pt;
  background-image: url('shotgun.jpg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: top right;
}
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
#landing-bg {
  height: 100vh;
  background-image: url('3watches.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}
#rifle {
  height: 78vh;
  background-image: url('rifle.jpg');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: top right;
}

#pistol {
  height: 300pt;
  background-image: url('pistol.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left center;
}
#shotgun {
  height:100pt;
  background-image: url('shotgun.jpg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: top right;
}
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  
#landing-bg {
  height: 100vh;
  background-image: url('3watches.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#rifle {
  height: fit-content;
  background-image: url('rifle.jpg');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 75pt;
  margin-bottom: 100pt;
}
#pistol {
  height: 500pt;
  background-image: url('pistol.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: -200pt;
}
#shotgun {
  height:100pt;
  background-image: url('shotgun.jpg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: top right;
}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

.image-container {
  height:30vh;
}

#landing-bg {
  height: 100vh;
  background-image: url('3watches.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#notification-complication {
  height: 50vh;
  background-image: url('notificationComplication.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#rifle {
  height: 77vh;
  background-image: url('rifle.jpg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: top right;
}

#pistol {
  background-image: url('pistol.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#shotgun {
  height: 30vh;
  background-image: url('shotgun.jpg');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom center;
}
#landing-content {
  margin-bottom: 150pt;
}

}

